define('app/Fontsizer',['jquery', 'app/utils'], function($, utils) {

	// Constructor
	var Fontsizer = function(control, element) {
		
		// Properties
		this.control = control;
		this.el = element;
		this.sizeLinks = control.find('a[data-font-size]');
		this.sizeArray = [];

		var _this = this;
		
		// Methods
		this.init = function() {
			
			this.userInteractions();

			// Push all class names to array
			this.sizeLinks.each(function() {
				_this.sizeArray.push('is-' + $(this).attr('data-font-size') + '-font-size');
			});

			// Set font size class from cookie
			this.el.addClass(utils.getCookie('font-size-class'));
		};

		this.userInteractions = function() {

			this.sizeLinks.on('click.sizeLinks', function() {
				
				// Record font size data attribute
				var size = $(this).attr('data-font-size');
				_this.resizeText(size);

				return false;
			});
		};

		this.resizeText = function(size) {

			// Create class name
			var sizeClass = 'is-' + size + '-font-size';

			if(!_this.el.hasClass(sizeClass)) {

				// Remove any previous size classes
				$.each(_this.sizeArray, function(i) {
					_this.el.removeClass(_this.sizeArray[i]);
				});

				// Add new size class
				_this.el.addClass(sizeClass);

				// Set browser cookie
				this.setCookie(sizeClass);
			}

		};

		this.setCookie = function(sizeClassValue) {
			utils.createCookie('font-size-class', sizeClassValue);
		};
	};

	return Fontsizer;
});	
