require.config({
    baseUrl: '/wp-content/themes/migration-museum-project/dev/js',
    paths: {
        // jquery: '//ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min',
        // jquery: 'lib/jquery-1.11.0.min',
        //'picturefill': 'lib/picturefill', // local file not working for some reason - require gets wrong path
        'picturefill': '//cdnjs.cloudflare.com/ajax/libs/picturefill/2.2.0/picturefill.min', // swapped this with the above
        'match': 'lib/media.match.min'
    },
    shim: {
        'picturefill': {
            exports: 'picturefill',
            deps: ['match']
        }
    }
});

// Load picturefill straight away
require(['picturefill'], function (picturefill) {
    picturefill();
});

// Test if Wordpress has loaded jquery
if (typeof jQuery === 'function') {
    define('jquery', [],function () { return jQuery; });
}

// Browser fixes
require(['jquery', 'app/browser-fixes'], function ($, browserFixes) {

    $(document).ready(function () {

        // Initialize browser polyfills

        if ($('body').hasClass('ie8')) {
            browserFixes.polyfills();
            browserFixes.svgFallback();
        }
    });
});

// Side sliding nav
require(['jquery', 'app/Sidenav'], function ($, Sidenav) {

    $(document).ready(function () {

        function navInits() {

            var navOverlay = $('<div class="nav-overlay">').prependTo('body');

            $('#nav-toggle').on('click', function () {
                if (!navOverlay.is(':visible')) { navOverlay.fadeIn(); }
            });

            Sidenav.allInstances = []; // Create array to store all instances of side navs

            mobileNav = new Sidenav({
                element: 'nav#menu-primary',
                toggle: 'a#nav-toggle',
                changeText: false,
                closeText: 'close'
            });

            mobileNav.initialize();
            Sidenav.allInstances.push(mobileNav); // Push instance to array

            mobileNav.closeBtn.on('click', function () { // Close button function
                if (navOverlay.is(':visible')) { navOverlay.fadeOut(); }
                mobileNav.closeNav();
                return false;
            });

            // $(navOverlay).on('click', function () {
            //     navOverlay.fadeOut();
            //     mobileNav.closeNav();
            // })
        }

        // Init / destroy on browser size
        var mobileInit = false;

        if ($(window).width() < 1024) {

            navInits();
            mobileInit = true;

        }

        // Destroy / init sidenav on browser resize
        $(window).on('resize', function () {

            if ($(window).width() <= 1023 && mobileInit === false) {
                navInits();
                mobileInit = true;
            }
            if ($(window).width() > 1023 && mobileInit === true) {
                mobileNav.destroy();
                mobileInit = false;
            }

        });

    });
});

// Accordions
require(['jquery', 'app/Accordion'], function ($, Accordion) {

    $(document).ready(function () {

        // Instantiation
        outputAccordion = new Accordion({
            element: 'section.latest-output > ul.mm-accordion',
            tabs: 'section.latest-output > ul.mm-accordion > li',
            tabsControl: 'section.latest-output > ul.mm-accordion > li h3:first-child',
            showFirst: true
        });
        outputAccordion.init();

        // Instantiation
        newsAccordion = new Accordion({
            element: 'section.latest-news > ul.mm-accordion',
            tabs: 'section.latest-news > ul.mm-accordion > li',
            tabsControl: 'section.latest-news > ul.mm-accordion > li h3:first-child',
            showFirst: true
        });
        newsAccordion.init();

        // Instantiation
        blogAccordion = new Accordion({
            element: 'section.latest-blog > ul.mm-accordion',
            tabs: 'section.latest-blog > ul.mm-accordion > li',
            tabsControl: 'section.latest-blog > ul.mm-accordion > li h3:first-child',
            showFirst: true
        });
        blogAccordion.init();

        // Instantiation
        educationNewsAccordion = new Accordion({
            element: 'section.latest-education-news > ul.mm-accordion',
            tabs: 'section.latest-education-news > ul.mm-accordion > li',
            tabsControl: 'section.latest-education-news > ul.mm-accordion > li h3:first-child',
            showFirst: true
        });
        educationNewsAccordion.init();

        // Instantiation
        resourceAccordion = new Accordion({
            element: 'section.latest-resource > ul.mm-accordion',
            tabs: 'section.latest-resource > ul.mm-accordion > li',
            tabsControl: 'section.latest-resource > ul.mm-accordion > li h3:first-child',
            showFirst: true
        });
        resourceAccordion.init();

        // Instantiation
        eventsAccordion = new Accordion({
            element: 'section.latest-events > ul.mm-accordion',
            tabs: 'section.latest-events > ul.mm-accordion > li',
            tabsControl: 'section.latest-events > ul.mm-accordion > li h3:first-child',
            showFirst: true
        });
        eventsAccordion.init();

        // Instantiation
        eventsAccordion = new Accordion({
            element: 'aside.latest-events--news > ul.mm-accordion',
            tabs: 'aside.latest-events--news > ul.mm-accordion > li',
            tabsControl: 'aside.latest-events--news > ul.mm-accordion > li h3:first-child',
            showFirst: false
        });
        eventsAccordion.init();

        // Instantiation
        educationEventsAccordion = new Accordion({
            element: 'section.latest-education-events > ul.mm-accordion',
            tabs: 'section.latest-education-events > ul.mm-accordion > li',
            tabsControl: 'section.latest-education-events > ul.mm-accordion > li h3:first-child',
            showFirst: true
        });
        educationEventsAccordion.init();

        // Instantiation
        archiveAccordion = new Accordion({
            element: 'aside.entry-archive ul.mm-accordion',
            tabs: 'aside.entry-archive ul.mm-accordion > li',
            tabsControl: 'aside.entry-archive ul.mm-accordion > li h3:first-child',
            showFirst: true
        });
        archiveAccordion.init();

        // Instantiation
        exhibitionsAccordion = new Accordion({
            element: 'section.latest-exhibitions > ul.mm-accordion',
            tabs: 'section.latest-exhibitions > ul.mm-accordion > li',
            tabsControl: 'section.latest-exhibitions > ul.mm-accordion > li h3:first-child',
            showFirst: true
        });
        exhibitionsAccordion.init();

    });

});

// Font sizing
require(['jquery', 'app/Fontsizer'], function ($, Fontsizer) {

    $(document).ready(function () {

        // Instantiation
        FontSizer = new Fontsizer($('ul.font-sizing'), $('html'));

        FontSizer.init();
    });

});

// Galleries
require(['jquery', 'picturefill', 'app/Slidefader'], function ($, picturefill, Slidefader) {


    // Custom picturefill_complete event added to Picturefill
    // Gallery init in seperate function
    // Conditional statement test whether picture element is supported and then runs appropriate statement 

    var initGallerySliders = function () {

        var gallerySliders = [];

        if ($('.galleryslider').length > 0) {
            $('.galleryslider').each(function (index, element) {

                gallerySliders[index] = new Slidefader({
                    element: element,
                    slides: '#' + element.id + ' > li'
                });

                gallerySliders[index].init();

            });
        }

        // Gallery autosliders
        function autoSliderFunc(autoSlider, slider) {
            autoSlider = setInterval(function () { slider.fadeToNext(slider.nextSlideRef); }, 5000);

            slider.el.hover(function () {
                clearInterval(autoSlider);
            }, function () {
                autoSlider = setInterval(function () { slider.fadeToNext(slider.nextSlideRef); }, 5000);
            });
        }

        var autoSliders = new Array();

        for (i = 0; i < gallerySliders.length; i++) {

            // Check more than one slide
            if (gallerySliders[i].slides.length > 1) {
                autoSliderFunc(autoSliders[i], gallerySliders[i]);
            }

        }

    }

    if (window.HTMLPictureElement) {

        $(document).ready(function () {
            initGallerySliders();
        });

    } else {

        $(window).on("picturefill_complete", function () {
            initGallerySliders();
        });
    }

});

// Rolling twitter feed tweets
require(['jquery', 'app/Listfader'], function ($, Listfader) {

    $(document).ready(function () {

        var tweetRollers = [];

        if ($('ul.mm_rolling-tweets').length > 0) {
            $('ul.mm_rolling-tweets').each(function (index, element) {

                tweetRollers[index] = new Listfader({
                    element: element,
                    items: '#' + element.id + ' > li',
                    rotateSpeed: 6000
                });
                tweetRollers[index].init();

            });
        }

    });

});

// Misc functions
require(['jquery', 'picturefill'], function ($, picturefill) {

    $(document).ready(function () {

        // Picturefill

        /* Included here to load - find better way of loading */

        // Swipebox

        // $( 'a' ).swipebox();

        // Search toggle

        var searchForm = $('#searchform');

        $('#search-toggle').on('click', function () {
            searchForm.toggle();
            $(this).toggleClass('is-active');

            return false;
        });

        // Slider caption hover

        $('ul.primaryslider').find('li').hover(function () {
            $(this).find('figcaption').stop().fadeIn();
        }, function () {
            $(this).find('figcaption').stop().fadeOut();
        });

        // Accordion filtered results (needs updating with ajax callback)

        $('#filter-results').on('click', '.tab-title', function () {
            $(this).parent().toggleClass('is-expanded');
            $(this).next().slideToggle();
        });

        // Open first result
        $('#filter-results').find('.tab-title').first().next().show().parent().addClass('is-expanded');

        // Block links clickable areas (to be consistent in old IE)

        var blockLink = $('ul.block-links').find('li');

        blockLink.css('cursor', 'pointer');

        blockLink.on('click', function () {

            window.location = $(this).find('a').attr('href');

        });

    });

});
define("main", function(){});

