define('app/browser-fixes',['jquery'], function($) {

	function polyfills() {

		// These polyfills are for IE8 when using equal-span() mixin

		// Footer links
		//$('div#footer-links > nav.menu:nth-of-type(5n+1)').addClass('pf-2-1');
		//$('div#footer-links > nav.menu:nth-of-type(5n+2)').addClass('pf-2-2');
		//$('div#footer-links > nav.menu:nth-of-type(5n+3)').addClass('pf-2-3');
		//$('div#footer-links > nav.menu:nth-of-type(5n+4)').addClass('pf-2-4');
		//$('div#footer-links > nav.menu:nth-of-type(5n+5)').addClass('pf-2-5');

		// Layout classes
		$('.l-half:nth-of-type(2n+1)').addClass('pf-6-1');
		$('.l-half:nth-of-type(2n+2)').addClass('pf-6-2');

		$('.resource-filter-field:nth-of-type(3n+1), .l-third:nth-of-type(3n+1)').addClass('pf-4-1');
		$('.resource-filter-field:nth-of-type(3n+2), .l-third:nth-of-type(3n+2)').addClass('pf-4-2');
		$('.resource-filter-field:nth-of-type(3n+3), .l-third:nth-of-type(3n+3)').addClass('pf-4-3');

	}

	function svgFallback() {
		// Check if browser can handle SVG
		if(!Modernizr.svg){
			// Get all img tag of the document and create variables
			var i=document.getElementsByTagName('img'),j,y;

			// For each img tag
			for(j = i.length ; j-- ; ){
				y = i[j].src;
				// If filenames ends with SVG
				if( y.match(/svg$/) ) {
					// Replace "svg" by "png"
					i[j].src = y.slice(0,-3) + 'png';
				}
			}
		}
	}

	return {
		polyfills: polyfills,
		svgFallback: svgFallback
	};

});
