define('app/Listfader',['jquery', 'app/utils'], function($, utils) {

	// Constructor
	var Listfader = function(options) {
		
		// Properties
		this.el = options.element;
		this.liItems = $(options.items);
		this.fadeSpeed = 300;
		this.rotateSpeed = options.rotateSpeed;
		
		// Private properties
		var _this = this;
		var listLength = this.liItems.length;
		var i = 0;
		
		// Methods
		this.init = function() {
			
			// Set list items to equal height
			this.liItems.show();
			utils.equalHeight(this.liItems);
			this.liItems.not(':first').hide();

			setInterval(this.fadeItems, this.rotateSpeed);
			
		};

		this.fadeItems = function() {

			_this.liItems.eq(i).fadeOut(this.fadeSpeed, function () {
				i += 1;
				if (i === listLength) {
					i = 0;
				}

				_this.liItems.eq(i).fadeIn(this.fadeSpeed);
			});
			
		};
		
	};

	return Listfader;
});	
