define('app/Accordion',['jquery'], function($) {

	// Constructor
	var Accordion = function(options) {
		
		// Properties
		this.el = $(options.element);
		this.tabs = $(options.tabs);
		this.tabsControl = $(options.tabsControl);
		this.showFirst = options.showFirst;

		var _this = this;
		
		// Methods
		this.init = function() {

			// Add classes
			this.tabsControl.addClass('tab-title');
			this.tabs.addClass('accordion-tab').first().addClass('is-expanded');

			// Set up expandable content
			this.tabsControl.each(function() {
				$(this).nextAll().wrapAll('<div class="accordion-content">');
			});
			
			// Close tabs
			if(this.showFirst) {
				this.closeTab(this.tabs.not(':first'));
			} else {
				this.closeTab(this.tabs);
			}			

			// User interactions
			this.userInteractions();
			
		};

		this.userInteractions = function() {

			this.tabsControl.on('click.accordion', function() {

				var tabParent = $(this).closest('.accordion-tab');

				if(!tabParent.hasClass('is-expanded')) {
					_this.expandTab(tabParent);
					_this.closeTab(_this.tabs.not(tabParent));


				} else {
					_this.closeTab($(this).closest('.accordion-tab'));
				}
			});

		};

		this.expandTab = function(tabs) {
			
			tabs.addClass('is-expanded').find('div.accordion-content').slideDown();

		};

		this.closeTab = function(tabs) {
			
			tabs.removeClass('is-expanded').find('div.accordion-content').slideUp();

		};
	};

	return Accordion;
});	
