define('app/Slidefader',['jquery'], function($) {

	// Constructor
	var Slidefader = function(options) {
		
		// Properties
		this.el = $(options.element);
		this.slides = $(options.slides);
		this.prevButton = $('<a href="#" class="sf-prev" data-icon="&#xe60d;"><span class="screen-reader-text">Previous</span></a>');
		this.nextButton = $('<a href="#" class="sf-next" data-icon="&#xe607;"><span class="screen-reader-text">Next</span></a>');
		this.nextSlideRef = 1; // Public for auto sliders	

		// Private properties
		var _this = this,
			topZIndex = 2,
			currentSlide = this.slides.first(),
			nextSlide,
			currentSlideRef = 0;
		
		// Methods
		this.init = function() {

			// Set viewport height
			this.el.css({
				height: this.slides.first().outerHeight()
			});

			// Add classes
			this.el.addClass('sf-viewport');
			this.slides.addClass('sf-slide');
			
			currentSlide.addClass('sf-slide--current').fadeIn();

			// Set initial z-index
			currentSlide.css({
				zIndex: topZIndex
			});

			// Set data-refs
			this.slides.each(function(i) {
				$(this).attr('data-sfref', i);
			});

			// Create buttons if more than one slide
			if(this.slides.length > 1) {
				this.nextButton.appendTo(this.el);
				this.prevButton.insertBefore(this.nextButton);
			}

			// Init user interactions
			this.userInteractions();
			
		};

		this.userInteractions = function() {

			// Next button
			this.nextButton.on('click.slidefader', function() {
				_this.fadeToNext();
				return false;
			});

			// Previous button
			this.prevButton.on('click.slidefader', function() {

				// Check if first slide
				if(currentSlideRef === 0) {
					_this.nextSlideRef = _this.slides.length - 1;
				} else {
					_this.nextSlideRef = currentSlideRef - 1;
				}

				// Fade next slide
				_this.fadeToNum(_this.nextSlideRef);
				return false;
			});

			// Window resize
			$(window).on('resize.slidefader', function() {
				_this.el.css({
					height: currentSlide.outerHeight()
				});
			});
		};

		this.fadeToNext = function() {

			// Check if last slide
			if(currentSlideRef == _this.slides.length - 1) {
				_this.nextSlideRef = 0;
			} else {
				_this.nextSlideRef = currentSlideRef + 1;
			}

			// Fade next slide
			_this.fadeToNum(_this.nextSlideRef);
		};

		this.fadeToNum = function(num) {

			// Cache next slide
			nextSlide = this.slides.filter('[data-sfref="' + num + '"]');
			
			// Reduce current slide z-index
			currentSlide.css('z-index', topZIndex - 1);

			// Move next slide to top of stack and fade in
			nextSlide.css('z-index', topZIndex)
			.fadeIn(500, function() {
				
				// Hide previous slide
				currentSlide.css('z-index', 0).hide();

				// Increment slide variables
				currentSlide = nextSlide;
				currentSlideRef = num;

			});
		};
	};

	return Slidefader;
});	
